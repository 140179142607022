<template>
    <div class="v-layer" v-if="isLayerVisible" :class="{ 'v-drop-layer': isLayerDropped }">
        <p @click="playSong" :class="{ 'fade-out': isTextFaded }">Click here to continue...</p>
    </div>

    <audio ref="audioPlayer" :src="audioSource" preload="auto" loop></audio>
</template>

<script>
export default {
    data() {
        return {
            audioSource: require('@/assets/song.mp3'),
            isLayerVisible: true,
            isLayerDropped: false,
            isTextFaded: false
        };
    },

    methods: {
        playSong() {
            this.$refs.audioPlayer.play();
            this.isLayerDropped = true;
            this.isTextFaded = true;
            
            setTimeout(() => {
                this.isLayerVisible = false; 
            }, 3000); 
        }
    }
};
</script>

<style scoped>
.v-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.932);
    backdrop-filter: blur(10px);
    transition: background-color 3s, backdrop-filter 3s;
}

div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(160, 160, 160);
}

p {
    cursor: pointer;
    transition: opacity 3s;
}

.v-drop-layer {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
}

.fade-out {
    opacity: 0;
}
</style>
