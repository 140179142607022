<template>
    <div class="content-box">
        <div class="content-box-header">
            <div class="content-box-header-avatar">
                <img src="../assets/avatar.png" alt="Avatar" class="avatar">
            </div>
            <div class="content-box-header-title">
                <h1>4VG</h1>
            </div>
            <div class="content-box-header-description">
                <h3>Względnie imponujący człowiek</h3>
            </div>
        </div>
        <div class="content-box-links">
            <div class="content-box-icon">
                <a :href="instagramUrl" target="_blank">
                    <font-awesome-icon :icon="['fab', 'instagram']" class="v-icon"/>
                </a>
            </div>
            <div class="content-box-icon">
                <a :href="snapchatUrl" target="_blank">
                    <font-awesome-icon :icon="['fab', 'snapchat']" class="v-icon"/>
                </a>
            </div>
            <div class="content-box-icon">
                <a :href="spotifyUrl" target="_blank">
                    <font-awesome-icon :icon="['fab', 'spotify']" class="v-icon"/>
                </a>
            </div>
            <div class="content-box-icon">
                <a :href="discordUrl" target="_blank">
                    <font-awesome-icon :icon="['fab', 'discord']" class="v-icon"/>
                </a>
            </div>
            <div class="content-box-icon" >
                <a :href="minecraftUrl" target="_blank">
                    <font-awesome-icon :icon="['fas', 'gamepad']" class="v-icon"/>
                </a>
            </div>
        </div>
    </div>
</template>



<script>

export default {
    data() {
        return {
            instagramUrl: 'https://www.instagram.com/fxlipxd/',
            snapchatUrl: 'https://www.snapchat.com/add/sleyzkrul',
            spotifyUrl: 'https://open.spotify.com/user/xrprjv224dadzuy2xfjm38ihi',
            discordUrl: 'https://discord.com/users/606507489093222417',
            minecraftUrl: 'https://pl.namemc.com/profile/PiotrZalewski.1'
        };
    }
};

</script>



<style scoped>
    .content-box {
        width: 350px;
        height: 450px;
        border-radius: 16px;;
        background: rgba(49, 49, 49, 0.22);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.24);
        backdrop-filter: blur(5.8px);
        -webkit-backdrop-filter: blur(5.8px);
        animation: contentBoxAnimation 5s infinite;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column
    }


    @keyframes contentBoxAnimation {
        0% {
            transform: scale(1);
        }


        50% {
            transform:  scale(0.8);
        }


        100% {
            transform: scale(1);
        }

    }

    .content-box-header {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }



    .content-box-header-avatar, .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0px 33px 39px -30pxrgba(0, 0, 0, 1);
    }


    .content-box-header-title {
        font-size: 20px;
        margin-top: 15px;
        color: white;
    }



    .content-box-header-description > h3 {
        font-size: 16px;
        color: rgb(224, 224, 224);
        font-weight: 400;
        margin-top: 5px;
    }



    .content-box-links {
        width: 180px;
        height: auto;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 40px;
    }


    .v-icon {
        font-size: 30px;
        margin: 10px;
        color: rgb(172, 172, 172);
    }


</style>