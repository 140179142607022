<template>
	<WebBackground />
	<ContentBox />
	<LoadLayer />
</template>



<script>
	import WebBackground from './components/WebBackground.vue'
	import ContentBox from './components/ContentBox.vue'
	import LoadLayer from './components/LoadLayer.vue';

	export default {
		name: 'App',
		components: {
			WebBackground,
			ContentBox,
			LoadLayer
		}
	}
</script>



<style>
	@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: Poppins;
	}


	body {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}
</style>