<template>
    <div class="background"></div>
</template>


<style scoped>
    .background {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/background.gif');
        background-size: cover;
        z-index: -1;
        filter: blur(5px);
    }
</style>